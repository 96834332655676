import React from 'react';
import HeaderMenu from './HeaderMenu';

function HeaderSub({ headline, tagline, selectedMenu, headerimage }) {
  if (!headerimage || headerimage.length< 3){
    headerimage = "img/headers/slide-1-1920x787.jpg";
  }
  return (
      <header className="section page-header page-header-1 context-dark">
        <div className="page-header-1-figure m-parallax">
          <div className="page-header-1-image m-parallax-image" style={{ backgroundImage: "url(" + headerimage + ")" }} />
        </div>
        {/* RD Navbar*/}
        <div className="rd-navbar-wrap">
          <nav className="rd-navbar rd-navbar-classic" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static" data-lg-stick-up-offset="1px" data-xl-stick-up-offset="1px" data-xxl-stick-up-offset="1px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
            <div className="rd-navbar-main-outer">
              <div className="rd-navbar-main">
                {/* RD Navbar Panel*/}
                <div className="rd-navbar-panel">
                  {/* RD Navbar Toggle*/}
                  <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span /></button>
                  {/* RD Navbar Brand*/}
                  <div className="rd-navbar-brand">
                    {/*Brand*/}<a className="brand" href="/">
                      <img className="brand-logo-dark" src="images/logo-default-268x50.png" alt="" width={134} height={25} />
                      <img className="brand-logo-light" src="images/logo-inverse-250x46.png" alt="" width={125} height={23} />
                    </a>
                  </div>
                </div>
                <div className="rd-navbar-nav-wrap">
                  {/* RD Navbar Nav*/}
                  <HeaderMenu selectedMenu={selectedMenu}></HeaderMenu>
                </div>
              </div>
            </div>
            <div className="rd-navbar-placeholder" />
          </nav>
        </div>
        <section className="breadcrumbs-custom">
          <div className="breadcrumbs-custom-inner">
            <div className="container">
              <div className="breadcrumbs-custom-main m-parallax-content">
                <h2 className="breadcrumbs-custom-title">{headline}</h2>
                <p>{tagline}</p>
              </div>
            </div>
          </div>
        </section>
      </header>
  )
}

export default HeaderSub;