import React from 'react';
import PressItem from './PressItem';
import PressArticle from './PressArticle';
import EmptyArticle from './EmptyArticle';

function Press() {
  return (
    <section className="section section-lg bg-default" id="section-press" data-type="anchor">
      {/** bg-gray-100 */}
      {/* eslint-disable-next-line */}
      <div className="container mt-30 mt-md-45 mt-xxl-75 text-center">
        <h2 className="wow fadeIn">Press releases</h2>
        <p className="text-gray-700 wow fadeIn" data-wow-delay=".025s">
          <span style={{ maxWidth: "655px" }}>
            Statements, announcements and press releases from the Arion Racing team.
          </span>
        </p>
        <div className="row row-30 row-xl-50 row-x-xl-50 mt-md-45 mt-xxl-70">
          <PressItem imgsrc={"scandinavia.jpg"} headline={"Arion Racing Scandinavia takes over the Arion S2 concept"} tagline={"AB Performance hands over the Arion S2 project"}>
            <PressArticle></PressArticle>
          </PressItem>
          <PressItem imgsrc={"history.jpg"} headline={"The History of Arion S2"}>
            <EmptyArticle></EmptyArticle>
          </PressItem>

          <PressItem imgsrc={"championship.jpg"} headline={"Arion Racing Championship"} externalLink={"https://www.facebook.com/ARCDanmark/"}>      
          </PressItem>
        </div>
      </div>
    </section>
  )
}

export default Press;