import React, { useEffect } from 'react';
import HeaderMain from './HeaderMain';
import Specs from './Specs';
import Gallery from './Gallery';
import CTA from './CTA';
import Contact from './Contact';
import Technical from './Technical';
import Press from './Press';
import Footer from './Footer';

function PageMain() {
  useEffect(() => {
    window.clientSetup();

    //Scroll to positition of #hash element when entering the page from a redirect
    if (window.location.hash) {
      try {
        const anchorTag = document.querySelector(window.location.hash);
        if (anchorTag) {
          const anchorPosition = window.scrollY + anchorTag.getBoundingClientRect().top
          window.scrollTo({ top: anchorPosition - 99, behavior: "smooth" })
        }
      } catch {
        // likely in a galley - ignore that
      }
    }
  }, []);

  return (
    <>
      <HeaderMain />

      <Specs />

      <div class="showcasebanner showcasebanner-black">
        <div class="showcasebanner-image" style={{ backgroundImage: 'url("/img/gallery/showroom1.jpg")' }}></div>
        <div class="showcasebanner-text">
          <div class="showcasebanner-text-inner">
            <h2>One of a kind</h2>
            <h5>What makes the Arion S2 unique?</h5>
            <p>
              A racer, at this level of performance, with low maintenance costs and high reliability, is unique.
            </p>
            <p>
            The powerful, yet robust Honda Fireblade 1000CC RR race engine provides, not only an explosive power output, but secures long term reliability. 
            This is due to the tried and tested engine core, the reliable gear box, combined with enhanced lubrication, the converted oil cooling and the air cooling.
            </p>
            <p>
            Due to exceptional handling, the Arion S2 is straightforward to drive - even at speeds that overshine most other race cars.
            </p>
          </div>
        </div>
      </div>

      <Gallery />
      
      <CTA />

      <Technical />

      <div class="showcasebanner showcasebanner-light">
        <div class="showcasebanner-image" style={{ backgroundImage: 'url("/img/highspeed-section.jpg")' }}></div>
        <div class="showcasebanner-text">
          <div class="showcasebanner-text-inner">
            <h2>Full Support</h2>
            <h5>Onsite Support</h5>
            <p>
              On track - specialized mechanics are ready to support the Arion S2 cars during race weekends<br />
              Spare parts are available on the track for uninterrupted racing!<br />
              <br />
               Need help setting the car up based on driving style and conditions? We are there to assist.
            </p>
            <h5 class="mt-5">Factory Support</h5>
            <p>
              Off track support maintenance, including preparation for race, repair of any damages, winter overhaul, or winter storage. We are building the Arion S2 wherefor we have the knowhow, tools and material to keep any S2 running for ages.<br />
              <br />
              If "arrive and drive" is what you are looking for, Arion Racing Scandinavia can handle transport, storage and general maintenance.
            </p>
          </div>
        </div>
      </div>

      <Press />

      <Contact />

      <Footer />
    </>
  )
}

export default PageMain;