import React from 'react';

function Gallery() {
    const gallery = [
        { img: "carsforsale.jpg", desc: "Cars for sale!" },
        { img: "fulltrack.jpg", desc: "Arion S2 filling the field" },
        { img: "birdseye1.jpg", desc: "On the track" },
        { img: "birdseye2.jpg", desc: "On the track" },
        { img: "chaseview.jpg", desc: "On the track" },
        { img: "fisheye.jpg", desc: "In the pit" },
        { img: "showroom1.jpg", desc: "Showroom" },
        { img: "walkaround.jpg", desc: "Car walk around", video: "walk.mp4?v=1" },
    ]

    let galleryJSX = [];
    let videoElementsJSX = [];
    let videoCounter = 0;
    for (let i = 0; i < gallery.length; i++) {
        let current = gallery[i];

        if (current.video) {
            videoCounter++;
            let videoId = "video" + videoCounter;
            galleryJSX.push(
                <div className="col-md-2 col-sm-6">
                    <span className="thumbnail-classic" data-poster={"img/gallery/" + current.img} data-sub-html={current.desc} data-html={"#" + videoId} data-lightgallery="item">
                        <a href="">
                            <img class="img-responsive" src={"img/gallery/" + current.img} />
                            <div class="thumbnail-play-icon-overlay">
                                <span class="mdi mdi-play-circle-outline"></span>
                            </div>
                            <div className="heading-5 thumbnail-classic-title">{current.desc}</div>
                        </a>
                    </span>
                </div>
            );
            videoElementsJSX.push(
                <div style={{ display: "none" }} id={videoId}>
                    <video className="lg-video-object lg-html5" controls preload="none">
                        <source src={"img/gallery/" + current.video} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            );

        } else {
            galleryJSX.push(
                <div className="col-md-2 col-sm-6">
                    <a className="thumbnail-classic" href={"img/gallery/" + current.img} data-sub-html={current.desc} data-lightgallery="item">
                        <img className="img-thumbnail" src={"img/gallery/" + current.img} alt="" width={383} height={290} />
                        <div className="heading-5 thumbnail-classic-title">{current.desc}</div>
                    </a>
                </div>
            );
        }
    }

    return (
        <section class="section section-md bg-gray-100 text-center text-sm-left" id="section-gallery">
            {videoElementsJSX}
            <div className="container">
                <h2 class="text-center">Gallery</h2>
                <h5 class="text-center mb-5">The Arion S2 in action and in the showroom</h5>
                <div className="row row-10 row-narrow" data-lightgallery="group">
                    {galleryJSX}
                </div>
            </div>
        </section>
    )
}

export default Gallery;