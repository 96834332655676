import React, { useEffect } from 'react';
import HeaderSub from './HeaderSub';
import Footer from './Footer';

function PageSub({ children, headerimage, headline, tagline, selectedMenu }) {
  
  useEffect(() => {
    window.clientSetup();
    window.scrollTo(0, 0);
    //if (menu) document.getElementById(menu).classList.add('active');    
  }, []);

  const goHome = () =>{
    window.location = "/";
  }

  return (
    <>
      <HeaderSub headerimage={headerimage} headline={headline} tagline={tagline} selectedMenu={selectedMenu} />
      <section className="section section-xs bg-default">
        <div className="container mt-10 mt-md-45 mt-xxl-75">
          <div onClick={goHome} className="button button-icon button-icon-left button-primary"><span className="icon mdi mdi-keyboard-backspace"></span><span>Home</span></div>
        </div>
      </section>
      {children}
      <Footer></Footer>
    </>
  )
}

export default PageSub;