import React from 'react';

function PressArticle() {
  return (
    <section className="section section-lg bg-default">
      <div className="container">
        <article className="blog-layout">
          <div className="blog-layout-main">
            <div className="blog-layout-main-item">
              <article className="post-corporate">
                <h3 className="post-corporate-title">Arion Racing Scandinavia takes over the Arion S2 concept</h3>
                <i><small>2021 Feb, 25th - Asger Thierry</small></i><br/>

                <p>By the end of September 2020, it was clear that, due to the long time lock down of the UK, the financial foundation for
                  AB performance was no longer in place.</p>

                <p>Arion Racing Scandinavia has imported and serviced Arion S2 race cars for a couple of years. We have gained in depth
                  knowledge about not only racing the car, but also servicing it – <b>this is a “one of a kind” race car!</b></p>

                <p>The technical knowledge and experience behind the design and brilliant technical solutions all over the car, has given
                  Arion Racing Scandinavia the full confidence that this is a car and a concept that will keep increasing in popularity.</p>

                <p>Having the option to take over the entire concept, as well as the full production foundation there were no doubt in our
                  minds – If AB Performance does not have the financial foundation to secure a continued success of this jewel we will!</p>

                <p>Many years of skilled craftmanship lies behind the production of Arion S2.</p>

                <p>Arion Racing wants to bring it to the next level, by making use of the latest technologies available today.</p>

                <p>3D scanning, CNC machined components, along with laser cut tubes and sheet metal, secures 100% accuracy and an optimised
                  production process.</p>

                <p>Arion S2 is a car composed of high-quality components, combining this with modern manufacturing technology preserves the
                  DNA of the car as a very reliable race car capable of going extremely fast.</p>

                <div className="post-corporate-gallery" data-lightgallery="group">
                  {/*} width="768" height="396" */}
                  <a className="post-corporate-thumbnail post-corporate-thumbnail-1" href="img/gallery/fulltrack.jpg" data-lightgallery="item">
                    <img className="post-corporate-thumbnail-image" src="img/gallery/fulltrack.jpg" alt="" width="768" height="396" />
                  </a>
                  {/* w: 758
                    <a className="post-corporate-thumbnail post-corporate-thumbnail-2" href="img/technical/frontsuspension.jpg" data-lightgallery="item">
                      <img className="post-corporate-thumbnail-image" src="img/technical/frontsuspension.jpg" alt="" width="278" height="331"/>
                    </a>
                    <a className="post-corporate-thumbnail post-corporate-thumbnail-3" href="img/technical/rearsuspension.jpg" data-lightgallery="item">
                      <img className="post-corporate-thumbnail-image" src="img/technical/rearsuspension.jpg" alt="" width="480" height="331"/>
                    </a>
                    */}
                </div>
                <p style={{ visibility: "hidden" }}>Pellentesque quis dui varius, dapibus velit id, iaculis ipsum. Morbi ac eros feugiat, lacinia elit ut
                    elementum turpis. Curabitur justo sapien, tempus sit amet.</p>
              </article>
            </div>
          </div>
        </article>
      </div>
    </section >
  )
}

export default PressArticle;