import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from './provider/GlobalStateProvider'

function App() {

  const [state, setState] = useContext(GlobalContext);

  return (
    <>
      {state.pageComponent}
    </>
  );
}

export default App;
