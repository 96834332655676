import React from 'react';

function SingleImage({ src }) {
    return (
        <div className="post-corporate-gallery" data-lightgallery="group">
            {/*} width="768" height="396" */}
            <a className="post-corporate-thumbnail post-corporate-thumbnail-1" href={"img/" + src} data-lightgallery="item">
                <img className="post-corporate-thumbnail-image" src={"img/" + src} alt="" width="768" height="396" />
            </a>
        </div>
        )
}

export default SingleImage;