import React, {useContext} from 'react';
import PageSub from './PageSub';
import { GlobalContext } from '../provider/GlobalStateProvider'

function TechnicalItem({ imgsrc, headline, tagline, children }) {
    
    const [state, setState] = useContext(GlobalContext);

    const onClickHandler = ()=>{
        setState({
            pageComponent : <PageSub headerimage={"img/headers/header1.jpg"} headline={headline} tagline={tagline} selectedMenu={""}>{children}</PageSub>,
            isFrontpage : false,
            selectedMenu : "technical"
        });
    };

    return (
        <div className="col-sm-6 col-lg-3 wow fadeIn">
            {/* eslint-disable-next-line */}
            <article className="post-creative"><a className="post-creative-image" href="#tech" onClick={onClickHandler} style={{ backgroundImage: "url(img/"+ imgsrc + ")" }}></a>
                <div className="post-creative-main">
                    <p className="post-creative-title"><a href="#tech" onClick={onClickHandler}>{headline}</a>
                        <br />
                    </p>
                    <p>{tagline}</p>
                </div>
            </article>
        </div>
    )
}

export default TechnicalItem;