import React, {useContext} from 'react';
import PageSub from './PageSub';
import { GlobalContext } from '../provider/GlobalStateProvider'


function PressItem({ imgsrc, headline, tagline, externalLink, children }) {
    const [state, setState] = useContext(GlobalContext);

    const onClickHandler = () => {
        if (externalLink){
            window.location = externalLink;
            return;
        }

        setState({
            pageComponent: <PageSub headerimage={""} headline={headline} tagline={tagline}>{children}</PageSub>,
            isFrontpage: false,
            selectedMenu : "press"
        });
    };

    return (
        <div className="col-sm-6 col-lg-4">
            <article className="tour-3 bg-image context-dark wow fadeIn" onClick={onClickHandler} style={{ backgroundImage: "url(img/" + imgsrc + ")" }}>
                <div className="tour-3-inner">
                    <div className="tour-3-main">
                        <h4 className="tour-3-title text-center">{headline}</h4>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default PressItem;